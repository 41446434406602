import React from "react";
import Helmet from "react-helmet";

// TODO: use Microdata or JSON-LD
export default () => (
  <div>
    <Helmet title="About" />
    <p>
      <p>
        Za kvalitetu prijevoda, točnost terminologije te jezičnu i gramatičku
        ispravnost vaših tekstova pobrinut će se Tanja Vohalski Adamić, magistra
        engleskog jezika i književnosti i magistra povijesti umjetnosti te
        sudska tumačica za engleski jezik s dugogodišnjim iskustvom u poslovima
        prevođenja, jezične redakture i lekture.
      </p>
      <p>
        Za dodatnu provjeru ispravnosti prijevoda, gramatike i pravopisa te
        dosljednosti terminologije brinu se stručni softverski alati za
        osiguravanje kvalitete prijevoda. Oni služe svim prevoditeljima kao
        drugi ili treći par očiju koji će dodatno provjeriti sve tekstove kako
        bi se osiguralo da se nije potkrala nijedna pogreška.
      </p>
      <p>
        Tu je naravno i razgranata mreža stručnih savjetnika i recenzenata s
        kojima svakodnevno kontaktiramo, savjetujemo se i surađujemo kako bi nam
        dodatno pomogli u osiguravanju najveće moguće stručnosti prijevoda kad
        je riječ o pojedinim specifičnim područjima koja zahtijevaju određena
        stručna znanja.
      </p>
    </p>
  </div>
);
